<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-inbox"></i><strong> Produits</strong>
              <info-message>
                Les produits représentent l'ensemble des récoltes qui peuvent être mises en vente.
              </info-message>
              <div class="card-header-actions">
                <b-button variant="success" @click="onExport" title="Exporter en PDF" class="mr-2" v-b-tooltip.hover>
                  <i class="fa fa-upload"></i>
                </b-button>
              </div>
            </div>
            <div class="text-center" v-if="fetchingProductList">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <div v-else>
              <c-table :table-data="products" :fields="fields" :actions="actions" :per-page=5 striped outlined>
                <div slot="empty" class="text-center p-2">Aucun produit dans l'exploitation.</div>
              </c-table>
              <b-modal title="Enregistrer une dépense" class="modal-primary" v-model="saleModal"
                       @hide="onAddModalCreateSaleClose" size="xl">
                <b-form-row>
                  <b-col class="mr-4" cols="6">
                    <c-input container-class="mb-3" label="Libellé de la vente" placeholder="Ex: Vente 1"
                             v-model="saleName" :state="saleNameState">
                      Veuillez saisir un nom
                    </c-input>
                    <c-input container-class="mb-3" type="quantity" label="Coût du transport" placeholder="Ex: 50000"
                             v-model="transportCost" :unit="exploitationCurrency"
                             step="500" :state="transportCostState">
                      Veuillez spécifier un coût
                    </c-input>
                    <b-form-row>
                      <b-col>
                        <c-input container-class="mb-3" type="select" label="Moyen de locomotion"
                                 v-model="transportMode"
                                 :options="transportModeList" :state="transportModeState">
                          <template slot="first">
                            <option :value="null">-- Select. le moyen de locomotion --</option>
                          </template>
                          Veuillez selectionner un moyen de locomotion
                        </c-input>
                      </b-col>
                      <b-col>
                        <c-input container-class="mb-3" type="select" label="Options de vente" v-model="saleOption"
                                 :options="saleOptionList" :state="saleOptionState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez l'option de vente --</option>
                          </template>
                          Veuillez selectionner une option de vente
                        </c-input>
                      </b-col>
                    </b-form-row>
                    
                    <b-input-group v-if="staff.length">
                      <b-badge variant="secondary" pill class="m-1 py-1" v-for="item in staff">
                        {{item.name}}
                        <b-link @click.prevent="removeStaff(item)">
                          <i class="fa fa-remove text-danger"></i>
                        </b-link>
                      </b-badge>
                    </b-input-group>
                    <p class="text-center text-muted" v-else>Aucun employé dans cette activité.</p>
                    <b-input-group class="mb-3">
                      <b-autocomplete-input placeholder="Rechercher un employé..." v-model="staffSearch"
                                            :data="staffAutocompleteData" class="autocomplete w-100"
                                            @hit="addStaff($event)" ref="staffAutocompleteInput"
                                            :serializer="item => item.name"
                                            :minMatchingChars=0>
                        <template slot="append">
                          <b-button variant="primary" @click.prevent="addStaff()">
                            Ajouter
                          </b-button>
                        </template>
                      </b-autocomplete-input>
                    </b-input-group>
                    
                    <b-form-row>
                      <b-col>
                        <c-input container-class="mb-3" type="quantity" label="Prix en détail" placeholder="Ex: 50000"
                                 v-model="retailPrice" :unit="exploitationCurrency + ' / Kg'"
                                 step="500" :state="retailPriceState">
                          Veuillez spécifier un coût
                        </c-input>
                      </b-col>
                      <b-col>
                        <c-input container-class="mb-3" type="quantity" label="Prix de gros" placeholder="Ex: 50000"
                                 v-model="wholesalePrice" :unit="exploitationCurrency + ' / 100Kg'"
                                 step="500" :state="wholesalePriceState">
                          Veuillez spécifier un coût
                        </c-input>
                      </b-col>
                    </b-form-row>
  
                    <c-input container-class="mb-3" type="datetime" label="Date de lancement" v-model="startDate"
                             :time.sync="startTime" :state="startDateTimeState">
                      Veuillez selectionnez une durée
                    </c-input>
                  </b-col>
                  <b-col>
                    <c-input type="image" v-model="picture" default="DEFAULT_INPUT_PICTURE"></c-input>
                    <c-input container-class="mb-3" type="quantity" label="Prevision des ventes" placeholder="Ex: 50000"
                             v-model="estimationSale" :unit="exploitationCurrency"
                             step="500" :state="estimationSaleState">
                      Veuillez spécifier un coût
                    </c-input>
                    <c-input container-class="mb-3" type="textarea" label="Description"
                             placeholder="Entrez une description..." v-model="comment">
                    </c-input>
                  </b-col>
                </b-form-row>
                <b-form-row class="b-a-1 p-1 justify-content-between">
                  <b-col cols="6">
                    <label class="font-weight-bold mb-0 text-muted">Répartitions dans les entrepôts</label>
                  </b-col>
                  <b-col cols="6">
                    <b-input-group class="font-weight-bold text-muted mt-2">
                      <b-input-group-prepend class="w-25">
                        <b-input-group-text class="w-100">Quantité totale =</b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="number" class="form-control"
                                    v-model="totalQuantity" />
                      <b-input-group-append class="unit">
                        <b-input-group-text class="w-100">Kg</b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-form-row>
                <b-form-row class="p-2">
                  <b-col cols="4" v-for="(warehouse, i) in warehouses">
                    <b-input-group class="mb-3">
                      <b-input-group-prepend class="w-50">
                        <b-input-group-text class="w-100">{{warehouse.name}}</b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="number" class="form-control" placeholder="Qté"
                                    v-model="warehouse.quantity"
                                    :state="warehouseStates[i]"
                                    :aria-describedby="`warehouse-quantity-${i}-feedback`"/>
                      <b-input-group-append class="unit">
                        <b-input-group-text class="w-100">Kg</b-input-group-text>
                      </b-input-group-append>
                      <b-form-invalid-feedback :id="`warehouse-quantity-${i}-feedback`">
                        Entrez une quantité valide inférieure à {{warehouse.maxQuantity}} (stock disponible)
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-col>
                </b-form-row>
                <div slot="modal-footer" class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitCreateSale"
                                  :fetching=fetchingCreateSale>
                    Enregistrer
                  </button-spinner>
                  <b-button variant="secondary" @click="saleModal = false" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </b-modal>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, price, Toast, Regex} from "../../../helpers"
  
  export default {
    name: "ExploitationProducts",
    title: "PIA - Produits",
    data() {
      return {
        
        saleModal: false,
        picture: null,
        saleName: '',
        staff: [],
        transportCost: '',
        transportMode: null,
        warehouses: [],
        retailPrice: '',
        wholesalePrice: '',
        saleOption: null,
        estimationSale: '',
        startDate: '',
        startTime: '',
        currentCulture: null,
        comment: '',
        staffList: [],
        
        transportModeList: [],
        saleOptionList: [],
        
        fetchingCreateSale: false,
        fetchingProductList: false,
        
        products: [],
        fields: [
          {key: 'name', label: 'Nom de la culture', sortable: true},
          {key: 'quantity', label: 'Quantité totale disponible'},
          {key: 'warehouseQuantity', label: 'Entrepôts(quantités)'},
          {key: 'actions', label: 'Actions'},
        ],
        actions: [
          {type: 'success', icon: 'icon-note', title: 'Mettre en vente', handler: this.saleCulture},
        ],
        
        submittedCreateSale: false
      }
    },
    created() {
      this.fetchingProductList = true
      Api.get('/exploitation/product/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.products = res.data.data.map(item => ({
              ...item,
              quantity: item.quantity + ' Kg',
              warehouseQuantity: item.products.map(prod => prod.warehouse.name + '(' + prod.warehouse.quantity + ')').join(', ')
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingProductList = false
        })
      Api.get('/exploitation/staff/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.staffList = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStaffList = false
        })
      Api.get('/exploitation/transport-mode/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.transportModeList = res.data.data.map(mode => ({
              value: mode.id,
              text: mode.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStaffList = false
        })
      Api.get('/exploitation/sale-option/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.saleOptionList = res.data.data.map(option => ({
              value: option.id,
              text: option.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStaffList = false
        })
    },
    mounted() {},
    watch: {},
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      exploitationCurrency() {
        return price()
      },
      staffAutocompleteData() {
        return this.staffList
      },
      totalQuantity() {
        return this.warehouses.reduce((acc, val) => {
          if (val) return acc + parseFloat(val.quantity)
          else return acc
        }, 0)
      },
      saleNameState() {
        return !this.submittedCreateSale || this.saleName.trim().length > 3 ? null : false
      },
      transportCostState() {
        return !this.submittedCreateSale || Regex.isPositiveNumber(this.transportCost) ? null : false
      },
      transportModeState() {
        return !this.submittedCreateSale || this.transportMode != null ? null : false
      },
      saleOptionState() {
        return !this.submittedCreateSale || this.saleOption != null ? null : false
      },
      retailPriceState() {
        return !this.submittedCreateSale || Regex.isPositiveNumber(this.retailPrice) ? null : false
      },
      wholesalePriceState() {
        return !this.submittedCreateSale || Regex.isPositiveNumber(this.wholesalePrice) ? null : false
      },
      startDateTimeState() {
        return !this.submittedCreateSale || this.startDate != "" ? null : false
      },
      estimationSaleState() {
        return !this.submittedCreateSale || Regex.isPositiveNumber(this.estimationSale) ? null : false
      },
      warehouseStates() {
        return !this.submittedCreateSale ? [null] : this.warehouses.map(ware => {
          return Regex.isPositiveNumber(ware.quantity) && ware.quantity <= ware.maxQuantity ? null : false
        })
      },
      startDateTime(){
        if(this.startTime)
          return this.startDate + ' ' + this.startTime
        return this.startDate
      }
    },
    methods: {
      saleCulture(culture) {
        this.saleModal = true
        this.currentCulture = {
          id: culture.id,
          name: culture.name
        }
        this.warehouses = culture.products.map(item => ({
          warehouseId: item.warehouse.id,
          name: item.warehouse.name,
          quantity: '',
          maxQuantity: item.warehouse.quantity
        }))
      },
      addStaff(item) {
        if (!item && this.staffSearch.trim() !== '')
          item = this.staffAutocompleteData.find(
            el => el.name.toLowerCase().includes(this.staffSearch.toLowerCase())
          )
        
        if (item) {
          if (!this.staff.includes(item))
            this.staff.push(item)
        }
        else
          alert('Aucun élément trouvé !')
        
        this.clearStaffAutocompleteInput()
      },
      removeStaff(item) {
        this.staff = this.staff.filter(el => el.id !== item.id || el.user !== item.user)
      },
      
      clearStaffAutocompleteInput() {
        this.$refs.staffAutocompleteInput.inputValue = ''
        this.staffSearch = ''
      },
      onSubmitCreateSale() {
        this.submittedCreateSale = true
        if(!this.validCreateSale()) return
        this.fetchingCreateSale = true
        Api.post('/exploitation/product-sale/create', {
          exploitationId: this.exploitationId,
          cultureId: this.currentCulture.id,
          picture: this.picture,
          name: this.saleName,
          staff: this.staff,
          transportCost: this.transportCost,
          transportModeId: this.transportMode,
          warehouses: this.warehouses,
          retailPrice: this.retailPrice,
          wholesalePrice: this.wholesalePrice,
          saleOptionId: this.saleOption,
          estimationSale: this.estimationSale,
          startDate: this.startDateTime,
          comment: this.comment
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              Toast.success('Vente enregistrée avec succes')
              this.$router.push({
                name: 'ExploitationSales',
                params: {
                  id: this.exploitationId
                }
              })
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateSale = false
          })
      },
      onAddModalCreateSaleClose(){
        this.saleName = ''
        this.transportCost = ''
        this.transportMode = null
        this.saleOption = null
        this.startDate = ""
        this.startTime = ""
        this.retailPrice = ""
        this.wholesalePrice= ""
        this.estimationSale = ""
        this.staff = []
        this.submittedCreateSale = false
      },
      validCreateSale() {
        for(let i=0; i< this.warehouses.length; i++){
          if (!Regex.isPositiveNumber(this.warehouses[i].quantity) || this.warehouses[i].quantity > this.warehouses[i].maxQuantity)
            return false
        }
        return this.saleName.trim().length > 3
          && Regex.isPositiveNumber(this.transportCost)
          && this.transportMode != null
          && this.saleOption != null
          && this.startDate != ""
          && Regex.isPositiveNumber(this.retailPrice)
          && Regex.isPositiveNumber(this.wholesalePrice)
          && Regex.isPositiveNumber(this.estimationSale)
      }
    }
  }
</script>

<style scoped>

</style>
